import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { refillRoutes } from '../routes'

const RefillQuestionnairePage = () => {
  const history = useHistory()

  useEffect(() => {
    history.push(refillRoutes.home)
  }, [history])

  return null
}

export default RefillQuestionnairePage
